import React from 'react';
import '../Components/Css/Statisstic.css';

const StatisticSection = () => {
  return (
    <div className="statistics-section">
      <div className="heading">
        <h1>Explore millions of offerings tailored to your business needs</h1>
      </div>
      <div className="stats-cards">
        <div className="stats-card">
          <h2>10M+</h2>
          <p>products</p>
        </div>
        <div className="stats-card">
          <h2>100K+</h2>
          <p>suppliers</p>
        </div>
        <div className="stats-card">
          <h2>1,900</h2>
          <p>product categories</p>
        </div>
        <div className="stats-card">
          <h2>10+</h2>
          <p>countries and regions</p>
        </div>
      </div>
    </div>
  );
};

export default StatisticSection;
