import React from 'react';
import '../Components/Css/Location.css';
import buildingImage from './Images/Carousel/1-main-3571-2-55955-sc-v2com.1585208891.7107.jpg';

const Location = () => {
  return (
    <div className="location-page">
      <h1>Location</h1>
      <div className="location-container">
        <div className="location-left">
          <div className="location-image">
            <img src={buildingImage} alt="Building" />
          </div>
          <div className="location-details">
            <div className="location-info">
              <h2>Address</h2>
              <p>Sri Rama Complex, No.13, Third Floor, 5th Cross, 6th Block, Koramangala, Bengaluru – 560 095</p>
            </div>
            <div className="location-contact">
              <h2>Contact Number</h2>
              <p>+91 8129821094</p>
            </div>
          </div>
         
        </div>
        <div className="location-right">
          <div className="location-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.3389633049994!2d77.61830841430816!3d12.933515790877366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15a1a4f4a6a3%3A0x84dcdc9f718a8c6f!2sKoramangala%2C%20Bengaluru%2C%20Karnataka%20560034%2C%20India!5e0!3m2!1sen!2sus!4v1620914871886!5m2!1sen!2sus"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Google Maps"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
