import React from 'react';
import '../Components/Css/Services.css';

const services = [
    {
      title: 'Fast Shipping',
      description: 'Get your products delivered quickly and efficiently.',
      icon: '🚚',
    },
    {
      title: '24/7 Support',
      description: 'We are here to help you anytime, any day.',
      icon: '📞',
    },
    {
      title: 'Secure Payments',
      description: 'Your payments are safe with our secure payment gateway.',
      icon: '💳',
    },
    {
      title: 'Easy Returns',
      description: 'Hassle-free returns on all your purchases.',
      icon: '🔄',
    },
    {
      title: 'Gift Wrapping',
      description: 'Add a special touch with our gift wrapping service.',
      icon: '🎁',
    },
    {
      title: 'Exclusive Deals',
      description: 'Get the best deals and discounts available only to you.',
      icon: '🏷️',
    },
    {
      title: 'Order Tracking',
      description: 'Track your orders easily with our online tracking system.',
      icon: '📦',
    },
    {
      title: 'Loyalty Rewards',
      description: 'Earn rewards and points on every purchase you make.',
      icon: '🎉',
    },
    {
      title: 'Personalized Recommendations',
      description: 'Get product recommendations tailored just for you.',
      icon: '🔍',
    },
    {
      title: 'Bulk Ordering',
      description: 'Special discounts for bulk purchases and corporate orders.',
      icon: '📦',
    },
    {
      title: 'New Arrivals Updates',
      description: 'Stay updated with the latest products and trends.',
      icon: '🆕',
    },
    {
      title: 'Customer Reviews',
      description: 'Read honest reviews from our customers before making a purchase.',
      icon: '📝',
    },
  ];
  
  

const Services = () => {
  return (
    <div className="services">
      <h1>Our Services</h1>
      <div className="services-container">
        {services.map((service, index) => (
          <div key={index} className="service">
            <div className="service-icon">{service.icon}</div>
            <h2 className="service-title">{service.title}</h2>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
