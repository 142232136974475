import React from 'react';
import '../Components/Css/Tradesection.css';

const Tradesection = () => {
  return (
    <div className="card-section">
    <div className="card">
      <div className="icon">
        <i className="fa fa-th-large"></i>
      </div>
      <h3>Millions of business offerings</h3>
      <p>Explore products and suppliers for your business from millions of offerings worldwide.</p>
    </div>
    <div className="card">
      <div className="icon">
        <i className="fa fa-check-circle"></i>
      </div>
      <h3>Assured quality and transactions</h3>
      <p>Ensure production quality from verified suppliers, with your orders protected from payment to delivery.</p>
    </div>
    <div className="card">
      <div className="icon">
        <i className="fa fa-exchange-alt"></i>
      </div>
      <h3>One-stop trading solution</h3>
      <p>Order seamlessly from product/supplier search to order management, payment, and fulfillment.</p>
    </div>
    <div className="card">
      <div className="icon">
        <i className="fa fa-handshake"></i>
      </div>
      <h3>Tailored trading experience</h3>
      <p>Get curated benefits, such as exclusive discounts, enhanced protection, and extra support, to help grow your business every step of the way.</p>
    </div>
    <div className="card">
      <div className="icon">
        <i className="fa fa-globe"></i>
      </div>
      <h3>Global Reach</h3>
      <p>Expand your business beyond borders with our extensive international network.</p>
    </div>
    <div className="card">
      <div className="icon">
        <i className="fa fa-shield-alt"></i>
      </div>
      <h3>Secure Transactions</h3>
      <p>Conduct your business with confidence knowing your transactions are secure with us.</p>
    </div>
  </div>
  );
};

export default Tradesection;
