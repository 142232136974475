import React from 'react';
import '../Components/Css/AllProducts.css';

function AllProducts() {
  const categories = [
    { name: 'Grocery', img: 'https://miro.medium.com/v2/resize:fit:1107/1*kQ5xXaLUK3S8EwnU4-zCxg.png' },
    { name: 'Home', img: 'https://hips.hearstapps.com/hbu.h-cdn.co/assets/16/42/1280x640/landscape-1476907189-study.jpg?resize=1200:*' },
    { name: 'Patio & Garden', img: 'https://m.media-amazon.com/images/I/81ZepM5dBxL._AC_UF894,1000_QL80_.jpg' },
    { name: 'Fashion', img: 'https://www.vasyerp.com/image/manufacturing/fashion-accessories.jpg' },
    { name: 'Electronics', img: 'https://amgglobaltrading.com/assets/images/electonic-banner.jpg' },
    { name: 'Baby', img: 'https://cdn.cdnparenting.com/articles/2017/10/418461847-H.webp' },
    { name: 'Toys', img: 'https://m.media-amazon.com/images/I/61wwSy6xYmL._AC_UF1000,1000_QL80_.jpg' },
    { name: 'Health & Wellness', img: 'https://ruemag.com/wp-content/uploads/2019/06/06-19-19-WellnessProducts-1.jpg' },
    { name: 'Sports', img: 'https://5.imimg.com/data5/SELLER/Default/2023/5/311236634/AA/HG/XB/60633516/all-type-of-sports-equipment.png' },
    { name: "Automotive", "img": "https://myfitment.com/wp-content/uploads/2019/11/shutterstock_584959654.jpg" },
    { name: "Books", "img": "https://5.imimg.com/data5/PG/YG/BF/SELLER-6708125/stationery-products-500x500.png" },
    { name: "Beauty", "img": "https://ecommercephotographyindia.com/blog/wp-content/uploads/2022/07/beauty-products-1.jpg" },
    { name: "Office Supplies", "img": "https://5.imimg.com/data5/AK/ZN/CZ/SELLER-15948769/office-stationery-products.jpg" },
    { name: "Jewelry", "img": "https://m.media-amazon.com/images/I/81zBQpyZKwL.jpg" },
    { name: "Toys & Games", "img": "https://m.media-amazon.com/images/I/81uydWMGKNL.jpg" },
   
  ];

  return (
    <div className="all-products">
      <h1>Get it all right here</h1>
      <div className="category-container">
        {categories.map((category, index) => (
          <div key={index} className="item">
            <img src={category.img} alt={category.name} />
            <p>{category.name}</p>
          </div>
        ))}
      
      </div>
    </div>
  );
}

export default AllProducts;
